.horizontalSlider {
  position: relative;
  overflow: hidden; }
  .horizontalSlider:not(.touchDisabled) {
    touch-action: pan-y pinch-zoom; }
  [dir='rtl'] .horizontalSlider {
    direction: ltr;
    transform: scaleX(-1); }
  .horizontalSliderTray {
    overflow: hidden;
    width: 100%; }

.verticalSlider {
  position: relative;
  overflow: hidden; }
  .verticalSliderTray {
    overflow: hidden; }

.verticalTray {
  float: left; }

.verticalSlideTrayWrap {
  overflow: hidden; }

.sliderTray {
  display: block;
  list-style: none;
  padding: 0;
  margin: 0; }

.sliderAnimation {
  transition: transform 500ms;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  /* easeInOutCubic */
  will-change: transform; }

.masterSpinnerContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #f4f4f4; }
